export const SET_ACTIVE_ID_INFOGRAPHICS = '[infographics] active-id/set';
export type SetInfographicsActiveId = {
  type: typeof SET_ACTIVE_ID_INFOGRAPHICS;
  payload: string
}
export const setInfographicsActiveId = (
  newId: string,
): SetInfographicsActiveId => ({
  type: SET_ACTIVE_ID_INFOGRAPHICS,
  payload: newId,
});

export type InfographicsAction =
  | SetInfographicsActiveId;
