import * as actions from './actions';

export type InfographicsState = {
  infographicsActivePhaseId: string | null;
};

export const initialState: InfographicsState = {
  infographicsActivePhaseId: null,
};

const infographics = (
  state: InfographicsState = initialState,
  action: actions.InfographicsAction,
): InfographicsState => {
  switch (action.type) {
    case actions.SET_ACTIVE_ID_INFOGRAPHICS:
      return {
        ...state,
        infographicsActivePhaseId:
        (action as actions.SetInfographicsActiveId).payload,
      };
    default:
      return state;
  }
};

export default infographics;
